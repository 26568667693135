export default [
  {
    title: 'CMS',
    icon: 'Edit3Icon',
    tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Homepage',
        route: 'cms-homepage',
        resource: 'CMS',
        action: 'manage',
      },
      {
        title: 'About Us',
        route: 'cms-aboutus',
        resource: 'CMS',
        action: 'manage',
      },
      {
        title: 'Contact Us',
        route: 'cms-contactus',
        resource: 'CMS',
        action: 'read',
      },
      {
        title: 'FAQ',
        route: 'cms-faq',
        resource: 'CMS',
        action: 'read',
      },
      {
        title: 'Work With Us',
        route: 'cms-workwithus',
        resource: 'CMS',
        action: 'read',
      },
      {
        title: 'Users',
        route: 'UsersIndex',
        resource: 'CMS',
        action: 'read',
      },
      {
        title: 'Applications',
        route: 'ApplicationsIndex',
        resource: 'CMS',
        action: 'read',
      },
      {
        title: 'Clients',
        route: 'ClientsIndex',
        resource: 'CMS',
        action: 'read',
      },
      {
        title: 'Freelancers',
        route: 'FreelancersIndex',
        resource: 'CMS',
        action: 'read',
      },
      {
        title: 'Payments',
        route: 'PaymentsIndex',
        resource: 'CMS',
        action: 'read',
      },
      {
        title: 'Quiz',
        route: 'quizIndex',
        resource: 'CMS',
        action: 'read'
      },
      {
        title: 'Projects',
        route: 'projectsIndex',
        resource: 'CMS',
        action: 'read'
      },
      {
        title: 'Projects Settings',
        route: 'MilestonesIndex',
        resource: 'CMS',
        action: 'read'
      }
    ],
  },
]
